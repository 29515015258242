import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Button, Box, Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "65px 0 65px 0",
	"sm-padding": "60px 0 60px 0",
	"quarkly-title": "Images-11"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "50%",
			"lg-width": "100%",
			"lg-align-items": "center",
			"sm-padding": "0px 0px 0px 0px",
			"padding": "16px 16px 16px 16px",
			"flex-direction": "column",
			"align-items": "flex-start",
			"lg-margin": "0px 0px 0px 0px",
			"sm-margin": "0px 0px 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"sm-font": "--headline3",
			"margin": "0px 0px 36px 0px",
			"color": "--darkL2",
			"font": "--headline2",
			"lg-text-align": "center",
			"md-text-align": "left",
			"children": "Connect With Us"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--darkL2",
			"font": "--base",
			"lg-text-align": "center",
			"sm-margin": "0px 0px 30px 0px",
			"md-text-align": "left",
			"children": "For a sparkling clean space, trust Shenzhen Starnoon Technology Co., LTD."
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"align-self": "flex-start"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"margin": "24px 0px 0px 0px",
			"href": "/contacts",
			"type": "link",
			"text-decoration-line": "initial"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"lg-width": "100%",
			"margin": "0px 0px 0px 0px",
			"padding": "16px 16px 16px 16px",
			"sm-padding": "0px 0px 0px 0px",
			"sm-margin": "0px 0px 30px 0px",
			"flex-direction": "column",
			"display": "flex"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"height": "auto",
			"padding": "0px 0px 70% 0px",
			"transition": "transform 0.2s ease-in-out 0s",
			"hover-transform": "translateY(-10px)",
			"overflow-x": "hidden",
			"overflow-y": "hidden",
			"position": "relative",
			"transform": "translateY(0px)"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"position": "absolute",
			"width": "100%",
			"top": "auto",
			"left": 0,
			"src": "https://fridskinverdson.com/img/3.jpg",
			"object-fit": "cover",
			"display": "block",
			"right": 0,
			"bottom": "0px",
			"min-height": "100%"
		}
	}
};

const Contact = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Box {...override("box1")}>
				<Button {...override("button")}>
					Contacts
				</Button>
			</Box>
		</Box>
		<Box {...override("box2")}>
			<Box {...override("box3")}>
				<Image {...override("image")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Contact, { ...Section,
	defaultProps,
	overrides
});
export default Contact;